<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    toggleButton?: {
      viewMore: string;
      seeLess: string;
    };
    initialContentHeight?: number;
  }>(),
  {
    toggleButton: () => ({
      viewMore: "Ver mais",
      seeLess: "Ver menos",
    }),
    initialContentHeight: 190,
  }
);

const show = ref(false);
</script>

<template>
  <div class="expandable">
    <div
      class="expandable__content"
      :style="{
        height: show ? 'auto' : `${initialContentHeight}px`,
      }"
    >
      <slot></slot>
    </div>
    <div class="expandable__toggle">
      <div class="expandable__toggle__bg" v-if="!show"></div>
      <div class="expandable__toggle__button">
        <button @click="show = !show">
          <span>{{
            show ? toggleButton?.seeLess : toggleButton.viewMore
          }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.expandable {
  @apply w-full flex flex-col;

  &__content {
    @apply w-full overflow-y-hidden;
  }

  &__toggle {
    &__bg {
      @apply w-full h-[90px] mt-[-90px] bg-[linear-gradient(to_bottom,rgba(255,255,255,0),rgba(255,255,255,1))];
    }
    &__button {
      @apply w-full flex items-center pt-1;
      span {
        @apply text-primary text-[14px] leading-5;
      }
    }
  }
}
</style>
